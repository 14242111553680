// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '../css/public.scss'

import TrackEvents from './shared/tracks'

function fixHeader() {
  let scrollBarPosition = window.pageYOffset | document.body.scrollTop

  if (scrollBarPosition > 300) {
    document.getElementById('body').classList.add('fixed')
  } else {
    document.getElementById('body').classList.remove('fixed')
  }
}

window.addEventListener('DOMContentLoaded', function() {
  const scrollView = document.getElementById('body')

  if (scrollView !== null) {
    document.addEventListener('scroll', fixHeader, { passive: true })
  }
})

window.togglePassword = function() {
  const icon = document.getElementById('passwordToggle')
  icon.classList.toggle('open')
  const field = document.getElementById('user_password')

  if (field.type === 'password') {
    field.type = 'text'
  } else {
    field.type = 'password'
  }
}

new TrackEvents()
