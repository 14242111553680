export const setCookie = (name, value, days = 30, path = '/') => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString()
  document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=' + path
}

export const deleteCookie = (name, path = '/') => {
  setCookie(name, '', -1, path)
}

export const getCookie = (name) => {
  const cookies = document.cookie.split(';')
    .reduce((acc, cookieString) => {
      const [key, value] = cookieString.split('=').map(s => s.trim())
      if (key && value) {
        acc[key] = decodeURIComponent(value)
      }
      return acc
    }, {})
  return name ? cookies[name] || '' : cookies
}
