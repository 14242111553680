const ymID = 66634765
const sendYM = (eventName, params = {}) => {
  if (typeof window.ym === 'function') {
    window.ym(ymID, 'reachGoal', eventName, params)
  }
}

const sendGTM = (eventName, event_category, event_label) => {
  if (typeof window.gtag === 'function') {
    window.gtag('event', eventName, {
      'event_category': event_category,
      'event_label': event_label,
      'transport_type': 'beacon'
    })
  }
}

export { sendYM, sendGTM, ymID as default }
