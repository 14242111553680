import $ from 'jquery'
import * as events from './analytics'

import { getCookie } from './cookies'

export default class TrackEvents {
  constructor() {
    this.bindEvents()
  }

  bindEvents() {
    this.bindModalEvents()
    this.bindFormEvents()
    this.bindOnboardingEvents()
    this.bindUserEvents()
  }

  send(eventName, params) {
    const vals = Object.values(params)
    events.sendYM(eventName, params)
    const label = vals[1] === 'undefined' ? '' : vals[1]
    events.sendGTM(eventName, vals[0], label)
  }

  bindModalEvents() {
    $('#prices').on('show.bs.modal', () => {
      this.send('viewRatesModal', {path: window.location.pathname})
    })

    $('#referral').on('show.bs.modal', () => {
      this.send('showReferralModal', {path: window.location.pathname})
    })

    $('#referral .close').on('click', () => {
      this.send('closeReferralModal', {path: window.location.pathname})
    })

    $('#videoGuide').on('show.bs.modal', () => {
      this.send('viewVideo', {path: window.location.pathname, title: $('#video-title').html()})
    })
  }

  bindFormEvents() {
    $('#wb_token, #ozon_token').on('submit', (event) => {
      this.send('SubmitKey', {path: window.location.pathname, type: $(event.currentTarget)[0].id})
    })

    $('#trial_expired_form').on('submit', (event) => {
      const role = getCookie('mayak_wb_role')
      const section = $(event.currentTarget).data('section')

      this.send('trialExpired', {role: role, section: section})
    })
  }

  bindOnboardingEvents() {
    $('.onboarding-list-item').on('click', (event) => {
      const msg = $(event.currentTarget).find('.list-action').html()
      const type = $('.onboarding-modal').data('type') + ': '

      this.send('onboarding', {type: type, msg: type + msg})
    })

    $('.onboarding-banner').on('click', () => {
      const type = $('.onboarding-modal').data('type')
      const progress = $('.onboarding-modal').find('h1').html()

      this.send('openOnboarding', {type: type, progress: progress})
    })
  }

  bindUserEvents() {
    $('.js-plan-request').on('click', (event) => {
      this.send('gotoPayment', {rate: $(event.currentTarget).data('plan'), path: window.location.pathname})
    })

    $('a[target="_blank"]').on('click', (event) => {
      this.send('outbound', {url: $(event.currentTarget).href})
    })

    $('.export-paywall').on('click', () => {
      this.send('tryExportOnFreePlan', {path: window.location.pathname})
    })

    $('.hide-unlock-badge').on('click', () => {
      this.send('hideUnlockBadge', {path: window.location.pathname})
    })

    $('.product-tabs .nav-link').on('click', (event) => {
      const $this = $(event.currentTarget)
      const sectionId = $this.parent().attr('id')
      const labelText = $this.html()

      this.send('viewTab', {section: sectionId, label: labelText})
    })
  }

  sendCampaignEventHandler(userId) {
    this.send('viewCampaign', { user_id: userId })
  }

  sendAdvProductEventHandler(userId) {
    this.send('viewAdvProduct', { user_id: userId })
  }
}
